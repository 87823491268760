import React, { useMemo } from "react";

import TextInput, { TextInputProps, TextInputType } from "./base";

function parseNumber(value: string, min: number | undefined, max: number | undefined) {
  if (!/^\d+$/.test(value)) return { invalid: "Please enter a number" };
  const parsed = parseInt(value, 10);
  if (min !== undefined && parsed < min) return { invalid: `Value must be at least ${min}` };
  if (max !== undefined && parsed > max) return { invalid: `Value must not be above ${max}` };
  return { invalid: false as const, value: parsed };
}

/**
 * Allows users to input a required positive integer value
 */
export function RequiredNumericInput({
  min,
  max,
  ...props
}: Omit<TextInputProps<number>, "required" | "clearButton"> & {
  min?: number;
  max?: number;
}) {
  const type = useMemo<TextInputType<number>>(
    () => ({
      inputType: "number",
      toString(value) {
        return value.toString();
      },
      parse(value) {
        return parseNumber(value, min, max);
      },
    }),
    [min, max],
  );

  return <TextInput type={type} min={min} max={max} {...props} required />;
}

/**
 * Allows users to input an optional positive integer value
 */
export function OptionalNumericInput({
  min,
  max,
  ...props
}: Omit<TextInputProps<number | null>, "required" | "clearButton"> & { min?: number; max?: number }) {
  const type = useMemo<TextInputType<number | null>>(
    () => ({
      inputType: "number",
      toString(value) {
        if (value === null) return "";
        return value.toString();
      },
      parse(value) {
        if (!value) return { invalid: false, value: null };
        return parseNumber(value, min, max);
      },
    }),
    [min, max],
  );

  return <TextInput type={type} min={min} max={max} {...props} />;
}
