import React, { FunctionComponent, PropsWithChildren, RefObject } from "react";

import PageHeader from ".";
import { ActionGroup } from "../../../../../shared/components/design-system/component-groups/ActionGroup";
import PageTitle from "./PageTitle";

type TitleWithActionGroupProps = PropsWithChildren<{
  title: string;
  className?: string;
  headerLevel?: 1 | 2 | 3 | 4;
  actionGroupRef?: RefObject<HTMLDivElement>;
}>;

/** @deprecated Use CombinedHeader instead of these components */
const TitleWithActionGroup: FunctionComponent<TitleWithActionGroupProps> = ({
  title,
  className,
  children,
  actionGroupRef,
  headerLevel = 1,
}) => (
  <PageHeader variant="has-actions" className={className}>
    {headerLevel === 1 ? (
      <PageTitle title={title} />
    ) : (
      <h1 className={`ds-h${headerLevel} ds-m-0 c-page-header__title`}>{title}</h1>
    )}

    <ActionGroup className="c-page-header__actions" ref={actionGroupRef}>
      {children}
    </ActionGroup>
  </PageHeader>
);

export default TitleWithActionGroup;
