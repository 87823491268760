import React, { FormEvent, useCallback, useEffect } from "react";

import TextInput from "../../../../../shared/components/design-system/TextInput";
import ButtonSet from "../../../../../shared/components/design-system/component-groups/ButtonSet";
import useBoolean from "../../../hooks/useBoolean";
import useStateWithInputChange from "../../../hooks/useStateWithInputChange";
import Button from "../../design-system/Button";

interface ConfirmForgotPasswordFormProps {
  onSubmit: (confirmationCode: string, newPassword: string) => void;
  onResend: () => void;
}

export default function ConfirmForgotPasswordForm({
  onSubmit: onSubmitProp,
  onResend: onResendProp,
}: ConfirmForgotPasswordFormProps) {
  const [code, setCode] = useStateWithInputChange("");
  const [password, setPassword] = useStateWithInputChange("");

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onSubmitProp(code, password);
    },
    [onSubmitProp, code, password],
  );

  const { value: resendLinkVisible, setTrue: showResendLink, setFalse: hideResendLink } = useBoolean(false);

  useEffect(() => {
    if (!resendLinkVisible) {
      const timer = setTimeout(showResendLink, 1000);
      return () => clearTimeout(timer);
    }
  }, [resendLinkVisible, showResendLink]);

  const onResend = useCallback(() => {
    hideResendLink();
    onResendProp();
  }, [onResendProp, hideResendLink]);

  return (
    <>
      <h1 className="ds-h3">Forgotten Password</h1>
      <p>We've sent a verification code to your email address. Please enter it below, along with your new password.</p>
      <form className="ds-mt-7" onSubmit={onSubmit}>
        <TextInput
          id="verification-code"
          label="Verification code"
          type="text"
          placeholder="e.g., 12345"
          autoFocus
          required
          autoComplete="one-time-code"
          value={code}
          onChange={setCode}
          pattern="\d*"
        />
        <TextInput
          id="new-password"
          label="New password"
          type="password"
          placeholder="New password"
          autoComplete="new-password"
          required
          value={password}
          onChange={setPassword}
        />

        <ButtonSet direction="vertical" className="ds-mt-7">
          <Button className="ds-mt-7" type="submit" size="large">
            Set new password
          </Button>
          {resendLinkVisible ? (
            <Button className="ds-mt-5" variant="secondary" onClick={onResend} size="large">
              Re-send verification code
            </Button>
          ) : null}
        </ButtonSet>
      </form>
    </>
  );
}
