import { LinkNode } from "@lexical/link";
import { $createListItemNode, $createListNode, ListItemNode, ListNode } from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createHeadingNode, HeadingNode } from "@lexical/rich-text";
import * as Sentry from "@sentry/browser";
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  LexicalNode,
  LineBreakNode,
  ParagraphNode,
  RootNode,
  TextNode,
} from "lexical";
import { useCallback, useEffect } from "react";

import { headingId } from "../../../../../../shared/block-editor-data/react";
import { RichTextDocument } from "../../../../../../shared/block-editor-data/types";

/** Listens for updates to the document, and if it enters an invalid state, fixes it. Also handles saving the document when it changes, and adding #ids to the headers for the header navigation to work with. */
export default function NormalisationPlugin({ onChange }: { onChange: (value: RichTextDocument) => void }) {
  const [editor] = useLexicalComposerContext();

  const normalise = useCallback(() => {
    editor.update(
      () => {
        const root = $getRoot();

        // This could be done in one pass, but it's nicer to break it up.
        removeBlockNodesFromParagraphs(root);
        removeDisallowedNodesFromLists(root);
        removeFormattingFromHeaders(root);
        wrapListItemsInLists(root);
        wrapTextNodesInParagraphs(root);
        combineAdjacentLists(root);
        insertEmptyParagraphs(root);
        alertUnexpectedNodeTypes(root);
      },
      { tag: "history-merge" },
    );

    // Mark up the headers for navigation links
    const headings = editor._rootElement?.querySelectorAll("h1, h2, h3, h4, h5, h6");
    if (headings) {
      for (let i = 0; i < headings.length; i++) {
        headings[i].setAttribute("id", headingId(`${headings[i].textContent}${i}`));
      }
    }

    // Push changes to the outer component
    onChange(editor.getEditorState().toJSON() as unknown as RichTextDocument);
  }, [editor, onChange]);

  useEffect(() => {
    editor.registerUpdateListener(normalise);
    normalise();
    return () => void editor._listeners.update.delete(normalise);
  }, [normalise, editor]);

  return null;
}

/** Tells you if a node is allowed to be inside a paragraph (or list item). That means text nodes and links — not (eg) list items, tables, etc. */
function canBeInParagraph(node: LexicalNode) {
  return node instanceof TextNode || node instanceof LinkNode;
}

/** We don't allow formatting or links in headers, so this replaces all their contents with a single text node that says the same thing. Also lock the heading tag to either H2 or H3 */
function removeFormattingFromHeaders(root: RootNode) {
  const nodes = root.getChildren();
  for (const node of nodes) {
    if (!(node instanceof HeadingNode)) continue;
    const tag = node.getTag();
    if (tag === "h2" || tag === "h3") {
      const children = node.getChildren();
      if (children.length === 0) continue;
      if (children.length === 1) {
        const [child] = children;
        if (child instanceof TextNode && !child.getFormat()) continue;
      }
    }

    const replacement = $createHeadingNode(tag < "h3" ? "h2" : "h3");
    replacement.append($createTextNode(node.getTextContent()));
    node.replace(replacement);
  }
}

/** If a block node (table, image, etc) has found its way into a paragraph (or heading) then this will remove it. */
function removeBlockNodesFromParagraphs(root: RootNode) {
  const nodes = root.getChildren();
  for (const node of nodes) {
    if (!(node instanceof ParagraphNode || node instanceof HeadingNode)) continue;
    removeBlockNodes(node, canBeInParagraph);
  }
}

/** If a block node (table, image, etc) has found its way into a list (or list item) then this will remove it. */
function removeDisallowedNodesFromLists(root: RootNode) {
  const nodes = root.getChildren();
  for (const node of nodes) {
    if (!(node instanceof ListNode)) continue;
    removeBlockNodes(node, (node) =>
      node.getParent() instanceof ListNode
        ? node instanceof ListItemNode
        : canBeInParagraph(node) || node instanceof ListNode,
    );
  }
}

type TextContainerNode = ParagraphNode | ListItemNode | HeadingNode | ListNode;

/** A helper function used by some of the above to remove certain nodes from their parents. Parent nodes are recursively split at the chosen ancestors, which are then inserted into the gaps. */
function removeBlockNodes(
  node: TextContainerNode,
  /** A function that returns true if the node can stay where it is, and false if we should remove it. */
  allowed: (node: LexicalNode) => boolean,
) {
  while (true) {
    // Split on the last bad child so that all the others will still be on the node and we can just keep going.
    const lastBadChild = getLastDescendantWhereNot(node, allowed);
    if (!lastBadChild) return;
    splitDocumentAt(lastBadChild);
  }
}

/** Find the last bad child in a node. */
function getLastDescendantWhereNot(node: LexicalNode, predicate: (node: LexicalNode) => boolean): LexicalNode | null {
  const queue: LexicalNode[] = node.getChildren();
  while (queue.length > 0) {
    const node = queue.pop()!;
    if (!predicate(node)) return node;
    const children = node.getChildren?.();
    if (!Array.isArray(children)) continue;
    queue.push(...children);
  }
  return null;
}

/** Splits all a node's ancestors up to (but not including) the root node. */
function splitDocumentAt(splitAt: LexicalNode) {
  // For the intermediate steps, we won't have a node to split on, so remove it first and that way every step will be the same.
  let splitAfter = splitAt.getPreviousSibling();
  let splitBefore = splitAt.getNextSibling();
  let nodeToSplit = splitAt.getParent()!;
  splitAt.remove();

  do {
    if (!splitAfter && !splitBefore) {
      // We've removed all the nodes in this child. Remove the current node and move to the next level up.
      splitAfter = nodeToSplit.getPreviousSibling();
      splitBefore = nodeToSplit.getNextSibling();
      const nextNodeToSplit = nodeToSplit.getParent()!;
      nodeToSplit.remove();
      nodeToSplit = nextNodeToSplit;
      continue;
    }
    if (!splitAfter) {
      // We're "splitting" right at the start of the node. Do nothing, but set the flags for the next level up.
      splitAfter = nodeToSplit.getPreviousSibling();
      splitBefore = nodeToSplit;
      nodeToSplit = nodeToSplit.getParent()!;
      continue;
    }
    if (!splitBefore) {
      // We're "splitting" right at the end of the node. Do nothing, but set the flags for the next level up.
      splitAfter = nodeToSplit;
      splitBefore = nodeToSplit.getNextSibling();
      nodeToSplit = nodeToSplit.getParent()!;
      continue;
    }

    // We're splitting mid-node, so we actually have to do some work.
    const newNode = clone(nodeToSplit as TextContainerNode);
    nodeToSplit.insertAfter(newNode);
    for (const child of [splitBefore, ...splitBefore.getNextSiblings()]) {
      child.remove();
      newNode.append(child);
    }
    splitAfter = nodeToSplit;
    splitBefore = newNode;
    nodeToSplit = nodeToSplit.getParent()!;
  } while (nodeToSplit.getType() !== "root");

  if (splitAfter) splitAfter.insertAfter(splitAt);
  else splitBefore!.insertBefore(splitAt);
}

function clone<T extends TextContainerNode>(node: T): T {
  if (node instanceof ParagraphNode) return $createParagraphNode() as T;
  if (node instanceof ListNode) return $createListNode(node.getListType()) as T;
  if (node instanceof ListItemNode) return $createListItemNode() as T;
  if (node instanceof HeadingNode) return $createHeadingNode(node.getTag()) as T;
  // @ts-ignore — Typescript thinks node is of type 'never' but still insists we need a return statement here, so
  throw new Error("Unexpected node type: " + node.getType());
}

/** Any orphaned list items that appear at the root level get wrapped in lists. */
function wrapListItemsInLists(root: RootNode) {
  let currentList: ListNode | null = null;
  const nodes = root.getChildren();
  for (const node of nodes) {
    if (!(node instanceof ListItemNode)) {
      currentList = null;
      continue;
    }

    if (!currentList) {
      currentList = $createListNode("bullet");
      node.insertBefore(currentList);
    }
    node.remove();
    currentList.append(node);
  }
}

/** Any orphaned text nodes that appear at the root level get wrapped in paragraphs. Line break nodes are simply removed. */
function wrapTextNodesInParagraphs(root: RootNode) {
  let currentParagraph: ParagraphNode | null = null;
  const nodes = root.getChildren();
  for (const node of nodes) {
    if (node instanceof LineBreakNode) {
      node.remove();
      currentParagraph = null;
      continue;
    }
    if (!(node instanceof TextNode) && !(node instanceof LinkNode)) {
      currentParagraph = null;
      continue;
    }

    if (!currentParagraph) {
      currentParagraph = $createParagraphNode();
      node.insertBefore(currentParagraph);
    }
    node.remove();
    currentParagraph.append(node);
  }
}

/** If you need two adjacent lists, put an empty paragraph between them. Otherwise, we assume you want one big list. */
function combineAdjacentLists(root: RootNode) {
  // Step through backwards so we can catch every instance in one pass
  const nodes = root.getChildren();
  for (let i = nodes.length - 1; i > 0; --i) {
    const last = nodes[i - 1];
    const next = nodes[i];
    if (last instanceof ListNode && next instanceof ListNode) {
      for (const item of next.getChildren()) {
        item.remove();
        last.append(item);
      }
      next.remove();
    }
  }
}

/** Inserts empty paragraphs at the start and end, and in-between block elements. This is done so that the user has somewhere to type. */
function insertEmptyParagraphs(root: RootNode) {
  const nodes = root.getChildren();

  if (nodes.length === 0) {
    root.append($createParagraphNode());
    return;
  }

  const firstNode = nodes[0];
  if (!isTypeable(firstNode)) {
    firstNode.insertBefore($createParagraphNode());
  }

  const lastNode = nodes[nodes.length - 1];
  if (!isTypeable(lastNode)) {
    lastNode.insertAfter($createParagraphNode());
  }

  for (let i = 1; i < nodes.length; ++i) {
    const node = nodes[i];
    if (!isTypeable(node) && !isTypeable(nodes[i - 1])) {
      node.insertBefore($createParagraphNode());
    }
  }
}

/** A "typable" node (a term made up for this file) is one that places a cursor within the top level of a document, which means you can use it to insert paragraphs, delete previous nodes, etc. That means a paragraph or a header — list nodes don't quite work for this (although they do do most of those things). */
function isTypeable(node: LexicalNode) {
  return node instanceof ParagraphNode || node instanceof HeadingNode;
}

const expectedNodeTypes = new Set([
  "text",
  "link",
  "linebreak",
  "paragraph",
  "heading",
  "list",
  "table",
  "image",
  "cs-analytics-chart--in-detail",
  "cs-analytics-chart--over-time",
]);
/** This doesn't actually do anything directly, but will alert Sentry if someone somehow manages to insert a type of node that we are not expecting. */
function alertUnexpectedNodeTypes(root: RootNode) {
  const nodes = root.getChildren();
  for (const node of nodes) {
    const type = node.getType();
    if (expectedNodeTypes.has(type)) continue;
    expectedNodeTypes.add(type);

    Sentry.captureMessage("Unexpected node type", { extra: { type } });
  }
}

// This is extremely useful for testing, so best not delete it even though it's not currently used and theoretically available in the git history
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function logDocument(node: LexicalNode, lines: string[] = [], indent = "") {
  lines.push(`${indent} - ${node.getType()} (${node.getKey()})`);
  for (const child of node.getChildren?.() ?? []) {
    logDocument(child, lines, indent + "  ");
  }
  if (!indent) console.log(lines.join("\n"));
}
