import { DateRange, QuestionSearch, ReportSearch } from "../../../../__generated__/graphql";
import { OrgData } from "../../hooks/useOrgData";
import { arrayIsEqualEnough, dateRangeIsEqualEnough, ReportSearchBools } from "./useFilterFromUrlParams";

type Question = OrgData["questions"][number];

// These are commonly useful things that seem too specific or calculation intensive to put in the context hook

// Returns an object listing which fields we're filtering on in a way different to the default filter.
// Fields are "false" if they are unfiltered or if they are filtered the same way as they are by default.
// Unfiltered fields are false even if the default filters filters on them.
// If all the fields come out false, the whole object is replaced with "false".
// This is designed to be used to say "should we show a filter chip here".
export function isViewFiltered(filter: ReportSearch, defaults: ReportSearch): ReportSearchBools | false {
  const fields = {
    createdAt: dateRangeExistsAndIsDifferent(filter.createdAt, defaults.createdAt),
    updatedAt: dateRangeExistsAndIsDifferent(filter.updatedAt, defaults.updatedAt),
    status: arrayExistsAndIsDifferent(filter.status, defaults.status),
    assignedTo: arrayExistsAndIsDifferent(filter.assignedTo, defaults.assignedTo),
    outcome: arrayExistsAndIsDifferent(filter.outcome, defaults.outcome),
    form: arrayExistsAndIsDifferent(filter.form, defaults.form),
    questions: questionSearchExistsAndIsDifferent(filter.questions, defaults.questions),
    excludeSpam: !!(filter.excludeSpam && !defaults.excludeSpam),
  };

  if (Object.values(fields).some((value) => value)) return fields;
  return false as const;
}

// Returns on object listing fields as "true" if the view's filter is in any meaningful way different to the default one, including being *less* filtered.
// If all the fields come out false, the whole object is replaced with "false".
// This is designed to be used to say "should we show this filter by default" or "should we enable the reset-filters button"
export function hasFilterChanged(filter: ReportSearch, defaults: ReportSearch): ReportSearchBools | false {
  const fields = {
    createdAt: !dateRangeIsEqualEnough(filter.createdAt, defaults.createdAt),
    updatedAt: !dateRangeIsEqualEnough(filter.updatedAt, defaults.updatedAt),
    status: !arrayIsEqualEnough(filter.status, defaults.status),
    assignedTo: !arrayIsEqualEnough(filter.assignedTo, defaults.assignedTo),
    outcome: !arrayIsEqualEnough(filter.outcome, defaults.outcome),
    form: !arrayIsEqualEnough(filter.form, defaults.form),
    questions: questionSearchExistsAndIsDifferent(filter.questions, defaults.questions),
    excludeSpam: !filter.excludeSpam !== !defaults.excludeSpam,
  };

  if (Object.values(fields).some((value) => value)) return fields;
  return false as const;
}

export function mixpanelFilterSummary(filter: ReportSearch, defaults: ReportSearch, questions: Question[]) {
  return {
    ...mixpanelDateSummary("createdAt", filter, defaults),
    ...mixpanelDateSummary("updatedAt", filter, defaults),
    ...mixpanelArraySummary("status", filter, defaults),
    ...mixpanelArraySummary("assignedTo", filter, defaults),
    ...mixpanelArraySummary("outcome", filter, defaults),
    ...mixpanelArraySummary("form", filter, defaults),
    ...mixpanelBooleanSummary("excludeSpam", filter, defaults),
    ...mixpanelQuestionSummary(questions, filter, defaults),
  };
}

type ReportSearchWith<K extends keyof ReportSearch, T> = ReportSearch & Partial<Record<K, T | null | undefined>>;

function mixpanelDateSummary<T extends keyof ReportSearch>(
  name: T,
  actual: ReportSearchWith<T, DateRange>,
  defaults: ReportSearchWith<T, DateRange>,
) {
  if (dateRangeIsEqualEnough(actual[name], defaults[name])) return {};
  return {
    [`${name}_start`]: !!actual[name]?.start,
    [`${name}_end`]: !!actual[name]?.end,
  };
}

function mixpanelArraySummary<K extends keyof ReportSearch, T>(
  name: K,
  actual: ReportSearchWith<K, T[]>,
  defaults: ReportSearchWith<K, T[]>,
) {
  if (arrayIsEqualEnough(actual[name], defaults[name])) return {};
  return { [name]: actual[name]?.length };
}

function mixpanelBooleanSummary<K extends keyof ReportSearch>(
  name: K,
  actual: ReportSearchWith<K, boolean>,
  defaults: ReportSearchWith<K, boolean>,
) {
  if (!!actual[name] === !!defaults[name]) return {};
  return { [name]: actual[name] };
}

function mixpanelQuestionSummary(questions: Question[], actual: ReportSearch, defaults: ReportSearch) {
  const diff = questionSearchExistsAndIsDifferent(actual.questions, defaults.questions);
  if (!diff) return {};
  const output: Record<string, number> = { otherQuestions: 0 };
  for (const key in diff) {
    if (!diff[key]) continue;
    const q = questions.find((q) => q.id === key);
    if (!q?.analyticsCode) {
      ++output.otherQuestions;
      continue;
    }
    const search = actual.questions?.find((q) => q.questionId === key);
    output[q.analyticsCode] = search!.value.length;
  }
  return output;
}

function dateRangeExistsAndIsDifferent(a?: DateRange | null, b?: DateRange | null) {
  return !!(a?.start || a?.end) && !dateRangeIsEqualEnough(a, b);
}

function arrayExistsAndIsDifferent<T>(a?: T[] | null, b?: T[] | null) {
  return !!a?.length && !arrayIsEqualEnough(a, b);
}

export function questionSearchExistsAndIsDifferent(
  a?: QuestionSearch[] | null,
  b?: QuestionSearch[] | null,
): false | Record<string, boolean> {
  if (!a?.length) return false as const;
  const changes: Record<string, boolean> = Object.fromEntries([
    ...a.map(({ questionId, value }) => [
      questionId,
      !arrayIsEqualEnough(value, b?.find((q) => q.questionId === questionId)?.value),
    ]),
    ...(b
      ?.filter(({ questionId }) => !a.some(({ questionId: aQuestionId }) => aQuestionId === questionId))
      .map(({ questionId }) => [questionId, true]) ?? []),
  ]);

  if (Object.values(changes).every((changed) => !changed)) return false as const;
  return changes;
}
