import React, { FormEvent, useCallback, useState } from "react";

import TextInput from "../../../../../shared/components/design-system/TextInput";
import ButtonSet from "../../../../../shared/components/design-system/component-groups/ButtonSet";
import useStateWithInputChange from "../../../hooks/useStateWithInputChange";
import Button from "../../design-system/Button";
import Checkbox from "../../design-system/Checkbox";

export default function ConfirmSignInForm({ onSubmit }: { onSubmit: (mfaCode: string, remember: boolean) => void }) {
  const [mfaCode, setMfaCode] = useStateWithInputChange("");
  const [askEveryTime, setAskEveryTime] = useState(true);

  const submit = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      onSubmit(mfaCode, !askEveryTime);
    },
    [mfaCode, askEveryTime, onSubmit],
  );

  return (
    <>
      <h2 className="ds-h3">Sign In</h2>
      <p>Please enter the 6 digit code from your authenticator app to continue.</p>
      <form className="ds-mt-7" onSubmit={submit}>
        <TextInput
          id="mfa-code"
          label="Verification code"
          type="text"
          placeholder="e.g., 123456"
          autoFocus
          required
          pattern="\d{6}"
          autoComplete="one-time-code"
          value={mfaCode}
          onChange={setMfaCode}
        />

        <Checkbox onChange={setAskEveryTime} isSelected={askEveryTime} className="ds-mt-5">
          Ask every time
        </Checkbox>

        <ButtonSet direction="vertical" className="ds-mt-7">
          <Button type="submit" disabled={mfaCode.length !== 6} size="large">
            Sign in
          </Button>
        </ButtonSet>
      </form>
    </>
  );
}
