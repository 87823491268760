import React, { PropsWithChildren, createContext, useContext } from "react";
import { Outlet, useParams } from "react-router-dom";

import Alert from "../../../../shared/components/design-system/Alert";
import useOrgData, { OrgData } from "../../hooks/useOrgData";

// This is exported so we can stub out the org data in tests and Storybook:
// @ts-ignore - the default value will never be used.
export const SiteDataContext = createContext<OrgData["sites"][number]>(null);

export function SiteDataProvider({ children }: PropsWithChildren<unknown>) {
  const { sites } = useOrgData();
  const { siteId } = useParams<"siteId">();
  const site = sites.find((site) => site.id === siteId);

  if (!site) {
    return <Alert variant="danger" message="The site you are attempting to access does not exist." />;
  }

  return <SiteDataContext.Provider value={site}>{children}</SiteDataContext.Provider>;
}

export function SiteDataRoute() {
  return (
    <SiteDataProvider>
      <Outlet />
    </SiteDataProvider>
  );
}

export default function useSiteData() {
  return useContext(SiteDataContext);
}
