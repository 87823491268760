import * as Sentry from "@sentry/browser";
import { useMemo } from "react";

import useDeepEquality from "../../../../../shared/hooks/useDeepEquality";
import { hasFilterChanged } from "../../../hooks/filtering/helpers";
import { FilterSettings, ReportSearchBools } from "../../../hooks/filtering/useFilterFromUrlParams";
import useOrgData, { OrgData } from "../../../hooks/useOrgData";
import { COLUMNS } from "../../../hooks/useReportFields";
import { statusText } from "../../reports/ReportStatus";

type Question = OrgData["questions"][number];

export type Panel = {
  id: string;
  name: string;
  question: null | Question;
  choices: null | { name: string; value: string | null }[];
  forms: null | boolean;
  matchText: string;
};

function panelChoices(
  columnId: string,
  teams: OrgData["teams"],
  outcomes: OrgData["outcomes"],
  { hideSpamOutcomes }: { hideSpamOutcomes: boolean },
) {
  switch (columnId) {
    case "status":
      return Object.entries(statusText)
        .filter(([code]) => code !== "Loading")
        .map(([code, { text }]) => ({ name: text, value: code }));
    case "assignedTo":
      return [
        ...teams.map(({ id, name }) => ({ name, value: id })),
        ...teams.flatMap((team) => team.members.map(({ id, name }) => ({ name, value: id }))),
      ];
    case "outcome":
      return [
        { name: "No outcome", value: null },
        ...outcomes
          .filter(({ suppressForAnalytics }) => !hideSpamOutcomes || !suppressForAnalytics)
          .map(({ id, name }) => ({ name, value: id })),
      ];
    default:
      return null;
  }
}

type OptionGroup = NonNullable<OrgData["questions"][number]["optionGroups"]>[number];

function matchTextForOption(option: OptionGroup["options"][number]) {
  return [option.value, ...option.valueTranslations.map(({ text }) => text)].join(" ");
}

const matchTextForOptionGroup = (focussedOptionId?: string) => (optionGroup: OptionGroup) =>
  [
    optionGroup.groupName ?? "",
    ...(optionGroup.groupNameTranslations?.map(({ text }) => text) ?? []),
    ...optionGroup.options
      .filter((option) => focussedOptionId === undefined || focussedOptionId === option.id)
      .map(matchTextForOption),
  ].join(" ");

export function buildMatchText(question: Question, focussedGroupId?: string, focussedOptionId?: string) {
  return [
    question.shortTitle,
    question.title,
    question.titleTranslations.map(({ text }) => text).join(" "),
    ...question.contextAwareTitles.map(({ title, titleTranslations }) =>
      [title, ...titleTranslations.map(({ text }) => text)].join(" "),
    ),
    ...(question.optionGroups
      ?.filter((group) => focussedGroupId === undefined || focussedGroupId === group.id)
      .map(matchTextForOptionGroup(focussedOptionId)) ?? []),
    ...(question.deletedOptionGroups
      ?.filter((group) => focussedGroupId === undefined || focussedGroupId === group.id)
      .map(({ groupName }) => groupName ?? "") ?? []),
    ...(question.deletedOptions
      ?.filter((option) => focussedOptionId === undefined || focussedOptionId === option.id)
      .map(({ value }) => value) ?? []),
  ].join(" ");
}

const NEVER_FILTER_BY = new Set(["key", "openTime", "triageTime"]);

export default function usePanels(
  showAll: boolean,
  rawDefaults: string[],
  hideSpamOutcomes: boolean,
  { filter, defaults: defaultFilters, forbiddenFilters }: FilterSettings,
) {
  const defaults = useDeepEquality(rawDefaults);
  const { questions, teams, outcomes, sites } = useOrgData();
  const filterHasChanged = useMemo(() => hasFilterChanged(filter, defaultFilters), [filter, defaultFilters]);

  return useMemo(() => {
    const panels: Panel[] = [
      ...COLUMNS.filter(({ id }) => !NEVER_FILTER_BY.has(id) && !matches(forbiddenFilters, id)).map(({ id, name }) => {
        const choices = panelChoices(id, teams, outcomes, { hideSpamOutcomes });

        return {
          id: id,
          name: name,
          question: null,
          choices,
          forms: id === "form" ? true : null,
          matchText:
            id === "form"
              ? [
                  name,
                  ...sites.flatMap(({ forms }) => [
                    ...forms.map(({ title }) => title),
                    ...forms.map(({ name }) => name),
                  ]),
                ].join(" ")
              : [name, ...(choices?.map(({ name }) => name) ?? [])].join(" "),
        };
      }),
      ...questions
        .filter(({ type }) => type === "RADIO" || type === "CHECKLIST")
        .map((question) => ({
          id: `question-${question.id}`,
          name: question.shortTitle,
          question,
          choices: null,
          forms: null,
          matchText: buildMatchText(question),
        })),
    ];
    panels.sort((a, b) => a.name.localeCompare(b.name));
    if (showAll) return panels;

    const filteredPanels = defaults
      .map((id) => panels.find((panel) => panel.id === id))
      .filter((panel) => panel) as Panel[];
    if (!filterHasChanged) return filteredPanels;

    for (const panel of panels) {
      if (matches(filterHasChanged, panel.id) && !filteredPanels.includes(panel)) {
        filteredPanels.push(panel);
      }
    }
    return filteredPanels;
  }, [outcomes, questions, teams, sites, forbiddenFilters, showAll, defaults, filterHasChanged, hideSpamOutcomes]);
}

function matches(filters: ReportSearchBools, id: string) {
  if (!filters) return false;
  switch (id) {
    case "status":
    case "form":
    case "createdAt":
    case "updatedAt":
    case "assignedTo":
    case "outcome":
      return filters[id];
    // These should never come up but they're valid column IDs so let's track them just in case.
    case "key":
    case "openTime":
    case "triageTime":
      return false;
    default:
      if (!id.startsWith("question-")) {
        // We should never get here — it means we've got a type of column this function hasn't been taught to expect. Therefore, log it to Sentry for production, and the console for development, then return false because that will usually produce the least annoying result.
        console.error("Unexpected column ID:", id);
        Sentry.captureException(new Error("Unexpected column ID:" + id));
        return false;
      }
      return filters.questions && filters.questions[id.substring(9)];
  }
}
