import { gql, useQuery } from "@apollo/client";
import React, { PropsWithChildren, createContext, useContext, useMemo } from "react";

import { GetSelfQuery } from "../../../__generated__/graphql";
import { DEFAULT_SUPERUSER_ORG_ID } from "../../../shared/db/special-case-orgs";
import { logErrorWithId } from "../components/design-system/GenericError";
import ErrorPage from "../components/global-furniture/ErrorPage";
import Loading from "../components/global-furniture/LoadingPage";
import { teamPermissions } from "../graphql/queries";

export type Self = GetSelfQuery["self"] & { isSuperAdmin: boolean };

// This is exported so we can stub out the self in tests and Storybook:
// @ts-ignore - the default value will never be used.
export const SelfContext = createContext<Self>(null);

export const SELF_QUERY = gql`
  query getSelf {
    self {
      id
      name
      email
      org {
        id
        name
        sector
        ssoProvider
        billingWarning
        featureGates
        isTestSite
        isSuspended
        sites {
          id
          name
          language
        }
      }
      team {
        id
        name
        ...teamPermissions
      }
      hasReportsAccess
      hasAnalyticsAccess
      hasSiteAccess
      hasUsersAccess
      hasFormsAccess
      canRedactReport
      canCreateTeam
      crossOrganisationPermissions {
        org {
          id
          name
        }
        permission
      }
      isSsoUser
      hasMfaEnabled
      acceptTracking
      receiveEmailNotifications
      receiveEmailAssignments
      receiveNewMessageEmails
      unreadFeatureAnnouncements
      answeredNewsletterCta
      viewSettings {
        name
        value
      }
    }
  }
  ${teamPermissions}
`;

export function SelfProvider({ children }: PropsWithChildren<unknown>) {
  const { data, error, loading } = useQuery<GetSelfQuery>(SELF_QUERY, { fetchPolicy: "cache-first" });

  const payload = useMemo<Self | null>(() => {
    if (!data) return null;
    return { ...data.self, isSuperAdmin: data.self.org.id === DEFAULT_SUPERUSER_ORG_ID };
  }, [data]);

  if (loading) return <Loading authState="loading" />;

  if (error) return <ErrorPage errorId={logErrorWithId(error)} />;

  return <SelfContext.Provider value={payload!}>{children}</SelfContext.Provider>;
}

export default function useSelf(): Self {
  return useContext(SelfContext);
}
