import mixpanel from "mixpanel-browser";
import React, { useCallback, useMemo } from "react";

import { filterSearchOptions } from ".";
import { FilterSettings } from "../../../hooks/filtering/useFilterFromUrlParams";
import FormPicker from "../FormPicker";
import { RadioButton } from "../RadioGroup";
import { SearchableList } from "../SearchableList";

export default function FormFilter({
  optionSearch,
  filterSettings: { filter, setFilter },
}: {
  optionSearch: string;
  filterSettings: FilterSettings;
}) {
  const selection = useMemo(() => filter.form ?? [], [filter]);
  const handleChange = useCallback(
    (value: string[]) => {
      mixpanel.track("Changed the reports filter", { name: "Form" });
      setFilter({ ...filter, form: value });
    },
    [setFilter, filter],
  );

  const selectAll = useCallback(() => {
    mixpanel.track("Changed the reports filter", { name: "Form" });
    setFilter({ ...filter, form: undefined });
  }, [filter, setFilter]);

  return (
    <div className="option-chooser">
      <SearchableList
        fields={["matchText"]}
        items={[{ id: "all", matchText: "Form" }]}
        searchText={optionSearch}
        component={() => (
          <RadioButton className="ds-pt-0" checked={!selection} onClick={selectAll}>
            All
          </RadioButton>
        )}
        searchOptions={filterSearchOptions}
      />
      <FormPicker selection={selection} onChange={handleChange} optionSearch={optionSearch} />
    </div>
  );
}
