import mixpanel from "mixpanel-browser";
import React, { PropsWithChildren, useCallback, useMemo } from "react";

import { AnalyticsCode } from "../../../../../../__generated__/graphql";
import upsellGraphic from "../../../../assets/images/any-question-upsell.svg";
import { AnalyticsCodeDescription, useAvailableAnalyticsCodes } from "../../../../graphql/values";
import { useOpenableWithTracking } from "../../../../hooks/useBoolean";
import UpsellModal from "../../../design-system/Dialog/UpsellModal";
import Select from "../../../design-system/Select";
import { SplitSettings } from "../useUrlSplitDimension";

function useFilteredCodes(codes: AnalyticsCodeDescription[] | null, exclude?: string[]) {
  return useMemo(() => {
    if (!codes) return null;
    if (!exclude) return codes;
    return codes.filter(({ code }) => !exclude.includes(code));
  }, [codes, exclude]);
}

export default function QuestionSelect({
  settings: { setQuestionId, setAnalyticsCode, clear, analyticsCode, questionId, optional },
  trackingName,
  label,
  className,
  exclude = [],
  clearMatchingSelect,
}: {
  settings: SplitSettings;
  trackingName: string;
  label: string;
  className: string;
  exclude?: SplitSettings[];
  clearMatchingSelect?: (newValue: string) => void;
}) {
  const { isOpen, open, close } = useOpenableWithTracking("Custom question analytics upsell modal");

  const { commonCodes, customCodes } = useAvailableAnalyticsCodes();
  const excludedCodes = useMemo(() => exclude.flatMap((e) => e.analyticsCode ?? `custom-${e.questionId}`), [exclude]);
  const commonCodesToShow = useFilteredCodes(commonCodes, excludedCodes)!;
  const customCodesToShow = useFilteredCodes(customCodes, excludedCodes);

  const onChange = useCallback(
    (code: string) => {
      if (code === "CUSTOM") {
        open();
        return;
      }
      if (clearMatchingSelect) {
        clearMatchingSelect(code);
      }
      mixpanel.track(`Changed Analytics in detail ${trackingName}`, { code });
      if (!code) clear();
      else if (code.startsWith("custom-")) setQuestionId(code.substring(7));
      else setAnalyticsCode(code as AnalyticsCode);
    },
    [trackingName, clear, setQuestionId, setAnalyticsCode, open, clearMatchingSelect],
  );

  const codeAsString = analyticsCode ?? (questionId ? `custom-${questionId}` : null);

  if (!customCodesToShow) {
    // This means they don't have the feature — if they do but there are no custom codes customCodesToShow will be []
    return (
      <>
        <CodeSelect label={label} value={codeAsString} onChange={onChange} className={className} required={!optional}>
          <OptionList codes={commonCodesToShow} />
          <option value="CUSTOM">Custom</option>
        </CodeSelect>
        <UpsellModal isOpen={isOpen} onClose={close} graphic={upsellGraphic} title="Analyse all questions">
          <p>
            The option to analyse all questions on your reporting form is only available to our <strong>Gold</strong>{" "}
            package partners.
          </p>
          <p>To find out more, speak to your Customer Success Manager.</p>
        </UpsellModal>
      </>
    );
  }

  if (customCodesToShow.length === 0) {
    return (
      <CodeSelect label={label} value={codeAsString} onChange={onChange} className={className} required={!optional}>
        <OptionList codes={commonCodesToShow} />
      </CodeSelect>
    );
  }

  return (
    <CodeSelect label={label} value={codeAsString} onChange={onChange} className={className} required={!optional}>
      <optgroup label="Built-in analytics questions">
        <OptionList codes={commonCodesToShow} />
      </optgroup>
      <optgroup label="Custom questions">
        <OptionList codes={customCodesToShow} />
      </optgroup>
    </CodeSelect>
  );
}

function CodeSelect({
  label,
  children,
  value,
  className,
  onChange,
  required,
}: PropsWithChildren<{
  label: string;
  value: string | null;
  className: string;
  onChange: (code: string) => void;
  required: boolean;
}>) {
  return (
    <Select label={label} value={value ?? ""} onChange={onChange} size="medium" className={className}>
      {required ? null : <option value="">None</option>}
      {children}
    </Select>
  );
}

function OptionList({ codes }: { codes: AnalyticsCodeDescription[] }) {
  return (
    <>
      {codes.map(({ code, label }) => (
        <option key={code} value={code}>
          {label}
        </option>
      ))}
    </>
  );
}
