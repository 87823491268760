import gql from "graphql-tag";

export const FORMS_QUERY = gql`
  query getForms {
    forms {
      id
      name
    }
  }
`;

const outcomeFields = gql`
  fragment outcomeFields on Outcome {
    id
    name
    guidance
    suppressForAnalytics
  }
`;

export const OUTCOMES_QUERY = gql`
  query getOutcomes {
    outcomes {
      ...outcomeFields
    }
  }
  ${outcomeFields}
`;

export const OUTCOMES_FOR_ORGANISATION_QUERY = gql`
  query getOutcomesForOrganisation($organisationId: ID!) {
    organisation(id: $organisationId) {
      id
      outcomes {
        ...outcomeFields
      }
    }
  }
  ${outcomeFields}
`;

export const UPDATE_OUTCOME = gql`
  mutation updateOutcome($id: ID!, $name: String!, $guidance: String, $suppressForAnalytics: Boolean!) {
    updateOutcome(id: $id, name: $name, guidance: $guidance, suppressForAnalytics: $suppressForAnalytics) {
      ...outcomeFields
    }
  }
  ${outcomeFields}
`;

export const DELETE_OUTCOME = gql`
  mutation deleteOutcome($id: ID!) {
    deleteOutcome(id: $id) {
      id
      outcomes {
        ...outcomeFields
      }
    }
  }
  ${outcomeFields}
`;

export const QUESTIONS_QUERY = gql`
  query getQuestions {
    questions {
      id
      shortTitle
      title
      type
      analyticsCode
      optionGroups {
        id
        groupName
        options {
          id
          value
        }
      }
      answersHidden
    }
  }
`;

export const ARTICLE_LIST_QUERY = gql`
  query getArticles($categoryId: ID!) {
    category(id: $categoryId) {
      id
      name
      articles {
        id
        name
        order
      }
    }
  }
`;
export const ORGANISATION_QUERY = gql`
  query getOrganisation($id: ID!) {
    organisation(id: $id) {
      id
      name
      sector
      billingWarning
      ssoProvider
      package
      featureGates
      isCoBranded
      isTestSite
      isSuspended
      crmId
      sites {
        id
        name
        language
        textOverride {
          textKey
          textValue
        }
        urls {
          id
          status
          host
          isCanonical
          cnameTarget
          certificateCnameOrigin
          certificateCnameTarget
          certificateValidUntil
        }
        forms {
          id
          name
          order
        }
      }
      questions {
        id
        title
        shortTitle
      }
      teams {
        id
        name
        createdAt
        notificationEmail
        members {
          id
          name
          email
          isSsoUser
          createdAt
          lastLogin
        }
      }
    }
  }
`;

export const SSO_TYPES_QUERY = gql`
  query ssoTypes {
    ssoTypes
  }
`;

export const teamPermissions = gql`
  fragment teamPermissions on Team {
    reportAccess
    customReportsAccessFilter {
      ... on CustomReportAccessFilterByQuestion {
        question {
          id
        }
        answers
      }
      ... on CustomReportAccessFilterByForm {
        forms {
          id
          name
        }
      }
    }
    analyticsAccess
    reportRedactionPermission
    teamAccess
    siteAccess
    surveyAccess
    formAccess
    managedTeams {
      id
      name
    }
    answerAccess
    customAnswerAccessFilter {
      hiddenQuestions
    }
  }
`;

export const TEAMS_QUERY = gql`
  query getTeams {
    teams {
      id
      name
      ...teamPermissions
    }
  }
  ${teamPermissions}
`;

export const ORGANISATION_TEAM_QUERY = gql`
  query getTeamsForOrganisation($organisationId: ID!) {
    organisation(id: $organisationId) {
      id
      teams {
        id
        name
        ...teamPermissions
      }
    }
  }
  ${teamPermissions}
`;

export const FORM_QUERY = gql`
  query getForm($id: ID!) {
    form(id: $id) {
      id
      updatedAt
      name
      slug
      title
      pages {
        type
        id
        groupIntro
        questions {
          id
          shortTitle
          title
          analyticsCode
        }
        body
        confirm
        onlyIf {
          questionId
          answers
        }
        allowSkipToPage
        showReview
        hasCaptcha
        hasLoginGate
      }
      showOnFrontPage
      active
      thankYouText
      additionalResourcesText
      type
      captchaSettings {
        scoreThreshold
        allowCaptchalessUntil
        siteKey
      }
      loginSettings {
        clientId
        clientUrl
      }
    }
  }
`;

// This query only fetches the sites you have permission to manage:
export const SITES_QUERY = gql`
  query getSites {
    sites {
      id
      name
      language
      textOverride {
        textKey
        textValue
      }
      urls {
        id
        host
      }
    }
  }
`;
