import { ClipboardEvent, useCallback } from "react";

import useStateWithInputChange from "./useStateWithInputChange";

export default function useTrimOnPaste(setState: (value: string) => void) {
  return useCallback(
    (ev: ClipboardEvent<HTMLInputElement>) => {
      ev.preventDefault();
      const pastedText = ev.clipboardData.getData("text").trim();
      setState(pastedText);
    },
    [setState],
  );
}

export function useInputWithTrimmedPaste(defaultValue: string) {
  const [value, changeValue, setValue] = useStateWithInputChange(defaultValue);
  const pasteValue = useTrimOnPaste(setValue);
  return [value, changeValue, pasteValue, setValue] as const;
}
