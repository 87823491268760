import React, { useCallback, useEffect, useState } from "react";

import { CustomGroupSet, MergedOptionSet, OptionGroup } from "../../../../../../../shared/components/charts/types";
import StringInput from "../../../../../../../shared/components/design-system/TextInput/StringInput";
import { useDebouncedValue } from "../../../../../../../shared/hooks/useDebounce";
import useRefOf from "../../../../../../../shared/hooks/useRefOf";
import useBoolean from "../../../../../hooks/useBoolean";
import IconButton from "../../../../design-system/Button/IconButton";
import Checkbox from "../../../../design-system/Checkbox";
import styles from "../index.module.scss";
import { isChecked } from "./grouper";

export function OptionList({
  groups,
  checked,
  touched,
  setChecked,
  addToGroup,
  renameGroup,
  ungroup,
}: {
  groups: CustomGroupSet;
  checked: string[];
  touched: string[];
  setChecked: (checked: string[]) => void;
  addToGroup: (id: string) => void;
  renameGroup: (id: string, label: string) => void;
  ungroup: (group: MergedOptionSet) => void;
}) {
  return (
    <>
      {groups.map((option) => {
        if ("id" in option) {
          return (
            <OptionListGroup
              group={option}
              checked={checked}
              touched={touched.includes(option.id)}
              setChecked={setChecked}
              addToGroup={addToGroup}
              renameGroup={renameGroup}
              ungroup={ungroup}
              key={option.id}
            />
          );
        }
        return <OptionListItem value={option} checked={checked} setChecked={setChecked} key={option.ids[0]} />;
      })}
    </>
  );
}

export function OptionListGroup({
  group,
  touched,
  checked,
  setChecked,
  addToGroup,
  renameGroup,
  ungroup,
}: {
  group: OptionGroup;
  touched: boolean;
  checked: string[];
  setChecked: (checked: string[]) => void;
  addToGroup: (id: string) => void;
  renameGroup: (id: string, label: string) => void;
  ungroup: (group: MergedOptionSet) => void;
}) {
  return (
    <>
      <OptionGroupHeader
        checked={checked}
        group={group}
        addToGroup={addToGroup}
        renameGroup={renameGroup}
        touched={touched}
      />

      <div className={styles.GroupMembers}>
        {group.members.map((option) => (
          <OptionListItem
            value={option}
            checked={checked}
            setChecked={setChecked}
            ungroup={ungroup}
            key={option.ids[0]}
          />
        ))}
      </div>
    </>
  );
}

export function OptionGroupHeader({
  group,
  checked,
  touched,
  addToGroup,
  renameGroup,
}: {
  group: OptionGroup;
  checked: string[];
  touched: boolean;
  addToGroup: (id: string) => void;
  renameGroup: (id: string, label: string) => void;
}) {
  const add = useCallback(() => addToGroup(group.id), [addToGroup, group.id]);

  const [inMemoryName, setInMemoryName] = useState(group.label);
  const { value: edited, setTrue: markEdited } = useBoolean(false);
  const onChange = useCallback(
    (name: string) => {
      markEdited();
      setInMemoryName(name);
    },
    [markEdited],
  );

  const debouncedName = useDebouncedValue(inMemoryName, 450);
  const renameGroupRef = useRefOf(renameGroup);
  const editedRef = useRefOf(edited);
  useEffect(() => {
    if (editedRef.current) renameGroupRef.current(group.id, debouncedName);
  }, [editedRef, renameGroupRef, group.id, debouncedName]);

  if (checked.length) {
    return (
      <div className={`${styles.GroupHeader} ${styles.CheckboxItem}`}>
        <div className={styles.CheckboxItem__label}>{inMemoryName}</div>
        <div className={styles.CheckboxItem__actions}>
          <IconButton
            size="xsmall"
            variant="secondary"
            onClick={add}
            className={styles.CheckboxItem__actionButton}
            icon="plus"
            label="Add to group"
          />
        </div>
      </div>
    );
  }

  return (
    <StringInput
      className={styles.GroupHeader}
      value={inMemoryName}
      onChange={onChange}
      label="Group button"
      autoFocus={!touched && !inMemoryName}
      size="medium"
    />
  );
}

export function OptionListItem({
  value,
  checked,
  setChecked,
  ungroup,
}: {
  value: MergedOptionSet;
  checked: string[];
  setChecked: (checked: string[]) => void;
  ungroup?: (group: MergedOptionSet) => void;
}) {
  // In practice it should always contain all or none of the IDs so there's no need to handle the partial case properly. As long as we don't crash, it's fine.
  const isSelected = isChecked(checked, value);
  const onChange = useCallback(() => {
    if (!isSelected) setChecked([...checked, ...value.ids]);
    else setChecked(checked.filter((id) => !value.ids.includes(id)));
  }, [checked, isSelected, setChecked, value.ids]);
  const ungroupItem = useCallback(() => ungroup?.(value), [ungroup, value]);

  return (
    <div className={styles.CheckboxItem}>
      <div className={styles.CheckboxItem__checkbox} onClick={onChange}>
        <Checkbox size="small" isSelected={isSelected}>
          {value.label}
        </Checkbox>
      </div>
      <div className={styles.CheckboxItem__actions}>
        {ungroup && !checked.length ? (
          <IconButton
            size="xsmall"
            variant="secondary"
            onClick={ungroupItem}
            className={styles.CheckboxItem__actionButton}
            icon="minus"
            label="Remove from group"
          />
        ) : null}
      </div>
    </div>
  );
}
