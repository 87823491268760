import { useCheckbox } from "@react-aria/checkbox";
import { useFocusRing } from "@react-aria/focus";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import { useToggleState } from "@react-stately/toggle";
import classNames from "classnames";
import React, { FunctionComponent, PropsWithChildren, useEffect, useRef } from "react";

import "./styles.scss";

export type CheckboxProps = PropsWithChildren<{
  id?: string;
  isSelected?: boolean;
  size?: "small" | "medium";
  isIndeterminate?: boolean;
  isDisabled?: boolean;
  onChange?: (isSelected: boolean) => void;
  isInvalid?: string | null;
  className?: string;
}>;

const Checkbox: FunctionComponent<CheckboxProps> = (props) => {
  const { isIndeterminate = false, isDisabled = false, isInvalid, className, children, size = "medium" } = props;
  const state = useToggleState(props);
  const inputRef = useRef<HTMLInputElement>(null);
  const { inputProps } = useCheckbox(props, state, inputRef);
  const { isFocusVisible, focusProps } = useFocusRing();

  const checkboxClass = classNames(
    {
      "c-checkbox": true,
      "is-small": size === "small",
      "is-focus-visible": isFocusVisible,
      "is-indeterminate": isIndeterminate,
      "is-disabled": isDisabled,
      "is-invalid": isInvalid,
      "is-checked": state.isSelected,
    },
    className,
  );

  useEffect(() => inputRef.current?.setCustomValidity(isInvalid ?? ""), [isInvalid]);

  return (
    <label className={checkboxClass}>
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={inputRef} className="c-checkbox__input" />
      </VisuallyHidden>

      <svg className="c-checkbox__svg" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 20 20">
        <g className="c-checkbox__svg-box" strokeWidth="1.5">
          <rect width="20" height="20" rx="2" stroke="none" />
          <rect x=".75" y=".75" width="18.5" height="18.5" rx="1.25" fill="none" />
        </g>

        <path
          className="c-checkbox__svg-tick"
          d="M15.313 6.501l-7.015 6.865-2.794-3.055"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path className="c-checkbox__svg-dash" d="M5.993 10h8.436" fill="none" strokeLinecap="round" strokeWidth="2" />
      </svg>

      {children ? <span className="c-checkbox__label">{children}</span> : null}
    </label>
  );
};

export default Checkbox;
