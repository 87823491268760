import { $generateHtmlFromNodes } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { LexicalNestedComposer } from "@lexical/react/LexicalNestedComposer";
import { mergeRegister } from "@lexical/utils";
import {
  $createRangeSelection,
  $getNodeByKey,
  $getRoot,
  COMMAND_PRIORITY_EDITOR,
  DecoratorNode,
  EditorConfig,
  LexicalCommand,
  LexicalEditor,
  LexicalNode,
  RangeSelection,
} from "lexical";
import React, { FunctionComponent, ReactNode, useContext, useEffect } from "react";

import { BlockBlock, Caption } from "../../../../../../shared/block-editor-data/types";
import { NestedContext } from "./NestedContext";
import $insertBlockNode from "./block-nodes/insert-block-node";
import { CustomBlockProps, SerializedCustomNode } from "./chart/block";
import { cellHTMLCache, cellTextContentCache } from "./table/TableNode";

/**
 Takes a React component with a particular interface and wraps it in a Lexical node plugin. Typescript will only accept it if the renderer and the type name match up with a BlockBlock defined in the shared block editor folder.

 It's suitable for most really custom block types, such as analytics charts. We don't use it for tables or images since those are more standard text editor things, which means they should support things like conversion to raw HTML for better copy-pasting.
*/
export function createCustomBlock<T extends BlockBlock, TOptions = T["csConfig"]>(
  name: T["type"],
  Component: FunctionComponent<CustomBlockProps<TOptions>>,
  version = 1,
) {
  // The values of these aren't important, they just have to exist and not be reference-equal to any other commands.
  const insert: LexicalCommand<TOptions> = {};
  const update: LexicalCommand<{ key: string; options: TOptions }> = {};

  // This is the node, a class that exists internally in the editor to represent the block.
  class CustomBlockNode extends DecoratorNode<ReactNode> {
    options: TOptions;
    version: number;
    caption: Caption;

    constructor(key: string | null, options: TOptions, caption: Caption | undefined, version: number) {
      super(key ?? undefined);
      this.options = options;
      this.version = version;
      this.caption = caption ?? createBlankCaption();
    }

    static getType(): string {
      return name;
    }

    static clone(node: CustomBlockNode): CustomBlockNode {
      return new CustomBlockNode(node.__key, node.options, node.caption, node.version);
    }

    decorate(editor: LexicalEditor, config: EditorConfig): ReactNode {
      return (
        <Component
          options={this.options}
          onChange={(options) => editor.dispatchCommand(update, { key: this.__key, options })}
          caption={this.caption}
          theme={config.theme}
          nodeKey={this.__key}
        />
      );
    }

    createDOM(config: EditorConfig): HTMLElement {
      return document.createElement("div");
    }

    updateDOM(prevNode: CustomBlockNode, dom: HTMLElement): boolean {
      return false;
    }

    isInline() {
      return false;
    }

    // The JSON export and import functions are used (a) to serialize the block for storage in the database, and (b) to allow the block's custom properties to survive a copy-paste.
    exportJSON(): SerializedCustomNode<TOptions> {
      return {
        version: this.version,
        type: name,
        csConfig: this.options,
        caption: this.caption,
      };
    }

    static importJSON(json: SerializedCustomNode<TOptions>): CustomBlockNode {
      return new CustomBlockNode(null, json.csConfig, json.caption, json.version);
    }

    updateCaptionJSON(json: string): void {
      const self = this.getWritable();
      self.caption = { ...self.caption!, json };
    }
  }

  // This returns a tiny React component which, when placed inside an editor, will register the above node and commands to that editor. In theory it could be a hook rather than a component, but in practice that's really inconvenient to call at the right time. Using it as a component solves that.
  function Plugin(): null {
    const [editor] = useLexicalComposerContext();

    useEffect(
      () =>
        mergeRegister(
          editor.registerCommand<TOptions>(
            insert,
            (options) => {
              $insertBlockNode(new CustomBlockNode(null, options, createBlankCaption(), version));
              return true;
            },
            COMMAND_PRIORITY_EDITOR,
          ),
          editor.registerCommand<{ key: string; options: TOptions }>(
            update,
            ({ key, options }) =>
              updateBlock(
                key,
                (existing) => new CustomBlockNode(null, options, existing.caption ?? createBlankCaption(), version),
              ),
            COMMAND_PRIORITY_EDITOR,
          ),
        ),
      [editor],
    );

    return null;
  }

  function $is(node: LexicalNode | null | undefined): node is CustomBlockNode {
    return node instanceof CustomBlockNode;
  }

  return { node: CustomBlockNode, Plugin, commands: { insert, update }, $is };
}

// Replaces a block by its key
export function updateBlock(key: string, create: (node: LexicalNode) => LexicalNode) {
  const node = $getNodeByKey(key);
  if (!node) return false;
  node.replace(create(node));
  return true;
}

export function focusNestedEditor(elem: HTMLElement, id: string): void {
  const nestedElem = elem.querySelector(`[data-id=${id}]`) as HTMLElement;
  if (nestedElem == null) {
    return;
  }
  nestedElem.focus();
}

export function NestedEditor({ nestedEditor }: { nestedEditor: LexicalEditor }) {
  const { nestedEditorConfig, nestedEditorPlugins } = useContext(NestedContext);
  if (nestedEditorConfig === null || nestedEditorPlugins === null) {
    return null;
  }

  return (
    <LexicalNestedComposer
      initialEditor={nestedEditor}
      initialTheme={nestedEditorConfig.theme}
      initialNodes={nestedEditorConfig.nodes}
      skipCollabChecks={true}
    >
      {nestedEditorPlugins}
    </LexicalNestedComposer>
  );
}

export function $createSelectAll(): RangeSelection {
  const sel = $createRangeSelection();
  sel.focus.set("root", $getRoot().getChildrenSize(), "element");
  return sel;
}

// Enables us to use the toolbar
export function isTargetOnPossibleUIControl(target: HTMLElement): boolean {
  let node: HTMLElement | null = target;
  while (node !== null) {
    const nodeName = node.nodeName;
    if (nodeName === "BUTTON" || nodeName === "INPUT" || nodeName === "TEXTAREA") {
      return true;
    }
    node = node.parentElement;
  }
  return false;
}

//for when we want a nested editors content to show when now not focused
export function generateHTMLFromJSON(editorStateJSON: string, nestedEditor: LexicalEditor): string {
  const editorState = nestedEditor.parseEditorState(editorStateJSON);
  let html = cellHTMLCache.get(editorStateJSON);
  if (html === undefined) {
    html = editorState.read(() => $generateHtmlFromNodes(nestedEditor, null));
    const textContent = editorState.read(() => $getRoot().getTextContent());
    cellHTMLCache.set(editorStateJSON, html);
    cellTextContentCache.set(editorStateJSON, textContent);
  }
  return html;
}

export function getCurrentDocument(editor: LexicalEditor): Document {
  const rootElement = editor.getRootElement();
  return rootElement !== null ? rootElement.ownerDocument : document;
}

export function getNestedElementId(domElement: HTMLElement | null): string | null {
  for (let node = domElement; node !== null; node = node.parentElement) {
    const possibleId = node.getAttribute("data-id");
    if (possibleId !== null) {
      return possibleId;
    }
  }
  return null;
}

export const emptyNestedEditorJSON =
  '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';

export function createBlankCaption(): Caption {
  return {
    id: createUID(),
    json: emptyNestedEditorJSON,
    direction: "ltr",
    format: "",
    type: "caption",
    version: 1,
    indent: 0,
  };
}

export function createUID(): string {
  return Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substring(0, 5);
}
