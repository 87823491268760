import optionalFieldProcessor from "../../../shared/translation/audit-log/processor";
import initialise from "../../../shared/translation/initialisation";
import globalResources from "./common/en-GB.json";

const i18n = initialise(optionalFieldProcessor);

importTranslations("translation", { "en-GB": globalResources });

export type DashboardLanguage = "en-GB";
export type TranslationSet = { [key: string]: string | TranslationSet };

export function importTranslations(namespace: string, translations: Record<DashboardLanguage, TranslationSet>) {
  for (const language in translations) {
    if (i18n.i18next.hasResourceBundle(language, namespace)) continue;
    i18n.i18next.addResourceBundle(language, namespace, translations[language as DashboardLanguage]);
  }
}
