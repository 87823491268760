import React, { useCallback, useMemo } from "react";

import { AnalyticsCode } from "../../../../../../__generated__/graphql";
import InputLabel from "../../../../../../shared/components/design-system/InputLabel";
import { FilterSettings } from "../../../../hooks/filtering/useFilterFromUrlParams";
import { TimePeriodSettings } from "../../../../hooks/filtering/useTimePeriodFromUrlParams";
import useReportFields, { Column } from "../../../../hooks/useReportFields";
import FilterChips from "../../../design-system/FilterChips";
import FilterPanel from "../../../design-system/FilterPanel";
import DateFilter from "../../DateFilter";
import { SplitSettings } from "../useUrlSplitDimension";
import QuestionSelect from "./QuestionSelect";
import GroupingButton from "./grouping/GroupingButton";
import styles from "./index.module.scss";

export default function ChartSelectorControls({
  className,
  firstSplit,
  secondSplit,
  filterSettings,
  datePickerSettings,
}: {
  className?: string;
  firstSplit: SplitSettings;
  secondSplit: SplitSettings;
  filterSettings: FilterSettings;
  datePickerSettings: TimePeriodSettings;
}) {
  const defaultPanels = useDefaultPanels(firstSplit, secondSplit);
  const clearSecondSplit = useCallback(
    (newValue: string) => {
      if (newValue === secondSplit.analyticsCode) {
        secondSplit.clear();
      }
    },
    [secondSplit],
  );
  return (
    <div className={`${styles.ChartSelectionPanel} ${className ?? ""}`}>
      <QuestionSelect
        settings={firstSplit}
        label="Report question"
        className={styles.ChartSelectionPanel__analysisCode}
        trackingName="question"
        clearMatchingSelect={clearSecondSplit}
      />
      <QuestionSelect
        settings={secondSplit}
        label="Split data"
        className={styles.ChartSelectionPanel__segmentBy}
        trackingName="split data by"
        exclude={[firstSplit]}
      />

      <div className={styles.ChartSelectionPanel__dateRange}>
        <InputLabel>Date range</InputLabel>
        <DateFilter datePickerSettings={datePickerSettings} className={styles.ChartSelectionPanel__dateRangePicker} />
      </div>

      <FilterPanel
        defaultPanels={defaultPanels}
        settings={filterSettings}
        className={styles.ChartSelectionPanel__filterButton}
      />

      <GroupingButton
        firstSplit={firstSplit}
        secondSplit={secondSplit}
        className={styles.ChartSelectionPanel__groupButton}
        filter={filterSettings.filter}
      />

      <FilterChips className={styles.ChartSelectionPanel__filterChips} settings={filterSettings} />
    </div>
  );
}

// Get the default filter panels — that is, anything we're splitting by, in order, deduped
function useDefaultPanels(firstSplit: SplitSettings, secondSplit: SplitSettings) {
  const availableFilters = useReportFields();
  return useMemo(() => {
    const defaultPanels: Column[] = [];

    function add(col: Column | null | undefined) {
      if (!col || defaultPanels.some((c) => c.id === col.id)) return;
      defaultPanels.push(col);
    }
    function addByQuestionId(questionId: string | null) {
      if (!questionId) return;
      add(availableFilters.find((col) => col.question?.id === questionId));
    }
    function addByAnalyticsCode(analyticsCode: AnalyticsCode | null) {
      if (!analyticsCode) return;
      for (const col of availableFilters) {
        if (col.analyticsCode === analyticsCode) defaultPanels.push(col);
      }
    }

    addByQuestionId(firstSplit.questionId);
    addByAnalyticsCode(firstSplit.analyticsCode);
    addByQuestionId(secondSplit.questionId);
    addByAnalyticsCode(secondSplit.analyticsCode);
    return defaultPanels;
  }, [
    availableFilters,
    firstSplit.analyticsCode,
    firstSplit.questionId,
    secondSplit.analyticsCode,
    secondSplit.questionId,
  ]);
}
