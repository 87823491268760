import {
  ApolloCache,
  DefaultContext,
  DocumentNode,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  TypedDocumentNode,
  useMutation,
} from "@apollo/client";
import * as Sentry from "@sentry/browser";

import { useToast } from "../../../shared/components/design-system/Toaster/context";
import { USER_INPUT_ERROR_CODE } from "../../../shared/errors";

export default function useMutationWithErrorReporting<
  TData,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<unknown> = ApolloCache<unknown>,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  { title, defaultMessage, showServerErrors }: { title?: string; defaultMessage?: string; showServerErrors?: boolean },
  mutationOptions: Omit<MutationHookOptions<TData, TVariables, TContext, TCache>, "onError"> = {},
): MutationTuple<TData, TVariables, TContext, TCache> {
  const toast = useToast();

  return useMutation(mutation, {
    ...mutationOptions,
    onError(error) {
      let unknownError = false;
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach((err) => {
          if (err.extensions.code === USER_INPUT_ERROR_CODE) {
            toast.error(error, { title });
            return;
          }
          // The caller can optionally force all well-formed errors to behave this way.
          if (showServerErrors) {
            toast.error(error.message, { title });
            return;
          }
          // Other errors should be sent to Sentry, and a basic error shown to the user.
          Sentry.captureException(error);
          if (!unknownError) {
            // Only show unknown error once
            toast.error(defaultMessage ?? "An unknown error occurred", { title });
            unknownError = true;
          }
        });
      }
    },
  });
}
