import { useMemo } from "react";

import { AnalyticsCode, QuestionAnalyticsCode } from "../../../__generated__/graphql";
import useOrgData from "../hooks/useOrgData";
import useSelf from "../hooks/useSelf";

export type AnalyticsCodeDescription = {
  code: string;
  label: string;
  long: string;
};

/** These are the analytics codes for doing analytics on — they include ones that aren't questions, such as form and outome. */
export const analyticsCodeDescriptions = [
  {
    code: AnalyticsCode.IncidentType,
    label: "Incident type",
    long: "What are people reporting?",
  },
  {
    code: AnalyticsCode.Form,
    label: "Report form",
    long: "How are people reporting incidents?",
  },
  {
    code: AnalyticsCode.Outcome,
    label: "Outcome",
    long: "What happened to submitted reports?",
  },
  {
    code: AnalyticsCode.Role,
    label: "Relationship to organisation",
    long: "Who's reporting?",
  },
  {
    code: AnalyticsCode.Faculty,
    label: "Department",
    long: "What departments are reporting?",
  },
  {
    code: AnalyticsCode.Location,
    label: "Location",
    long: "Where did the incidents occur?",
  },
  {
    code: AnalyticsCode.AnonymousReason,
    label: "Anonymous reason",
    long: "Why are people reporting anonymously?",
  },
  { code: AnalyticsCode.Gender, label: "Gender", long: "Which genders are reporting?" },
  // These are null because you should just use the text of the question here (since "are they trans" and "do tehy identify with their birth gender" make "yes" and "no" mean different things)
  { code: AnalyticsCode.Trans, label: null, long: null },
  {
    code: AnalyticsCode.Ethnicity,
    label: "Ethnicity",
    long: "Which races/ethnicities are reporting?",
  },
  {
    code: AnalyticsCode.SexualIdentity,
    label: "Sexual identity",
    long: "Which sexual identities are reporting?",
  },
  { code: AnalyticsCode.Age, label: "Age", long: "What are the ages of the reporters?" },
  {
    code: AnalyticsCode.Disability,
    label: "Disability",
    long: "Do the reporters identify with any disabilities?",
  },
  {
    code: AnalyticsCode.MessageOptIn,
    label: "Opt-in to messaging",
    long: "Does the reporter wish to be contacted for messaging?",
  },
  {
    code: AnalyticsCode.Religion,
    label: "Faith/religion",
    long: "What are the faiths/religions of the reporters?",
  },
  {
    code: AnalyticsCode.SuspectedCause,
    label: "Suspected factor",
    long: "Why did the incidents occur?",
  },
] as const;

/** These are the analytics codes that can be applied to questions — they include ones that don't really make sense to do analytics on */
export const questionAnalyticsCodeDescriptions = [
  { code: QuestionAnalyticsCode.IncidentType, label: "Incident type" },
  { code: QuestionAnalyticsCode.Role, label: "Relationship to organisation" },
  { code: QuestionAnalyticsCode.Faculty, label: "Department" },
  { code: QuestionAnalyticsCode.Location, label: "Location" },
  { code: QuestionAnalyticsCode.AnonymousReason, label: "Anonymous reason" },
  { code: QuestionAnalyticsCode.Gender, label: "Gender" },
  { code: QuestionAnalyticsCode.Trans, label: "Trans?" },
  { code: QuestionAnalyticsCode.Ethnicity, label: "Ethnicity" },
  { code: QuestionAnalyticsCode.SexualIdentity, label: "Sexual identity" },
  { code: QuestionAnalyticsCode.Age, label: "Age" },
  { code: QuestionAnalyticsCode.Disability, label: "Disability" },
  { code: QuestionAnalyticsCode.MessageOptIn, label: "Opt-In to Messaging" },
  { code: QuestionAnalyticsCode.Religion, label: "Faith/religion" },
  { code: QuestionAnalyticsCode.SuspectedCause, label: "Suspected factor" },
  { code: QuestionAnalyticsCode.ReportedBy, label: "Name of reporter" },
  { code: QuestionAnalyticsCode.ContactDetails, label: "Contact details" },
] as const;

export function useAvailableAnalyticsCodes(): {
  commonCodes: AnalyticsCodeDescription[];
  customCodes: AnalyticsCodeDescription[] | null;
  allCodes: AnalyticsCodeDescription[];
} {
  const { questions } = useOrgData();
  const self = useSelf();

  const common = useMemo(() => {
    const validDescriptions: AnalyticsCodeDescription[] = [];
    for (const description of analyticsCodeDescriptions) {
      if (description.code === AnalyticsCode.Form || description.code === AnalyticsCode.Outcome) {
        validDescriptions.push(description);
        continue;
      }
      const exampleQuestion = questions.find(
        (q) => q.analyticsCode && (q.analyticsCode as unknown as AnalyticsCode) === description.code,
      );
      if (!exampleQuestion) continue;
      validDescriptions.push({
        code: description.code,
        label: description.label ?? exampleQuestion.shortTitle,
        long: description.long ?? exampleQuestion.title,
      });
    }
    return validDescriptions;
  }, [questions]);

  const custom = useMemo(() => {
    if (!self.org.featureGates.includes("analyseAnyQuestion")) return null;
    const analysableQuestions = questions.filter((q) => {
      // Remove any question that is already uniquely identified by an analytics code
      if (!q.optionGroups) return false;
      if (!q.analyticsCode) return true;
      if (questions.some((other) => other.id !== q.id && other.analyticsCode === q.analyticsCode)) return true;
      return false;
    });
    return analysableQuestions.map((q) => ({
      code: `custom-${q.id}`,
      label: q.shortTitle,
      long: q.title,
    }));
  }, [questions, self]);

  const all = useMemo(() => (custom ? [...common, ...custom] : common), [common, custom]);

  return { commonCodes: common, customCodes: custom, allCodes: all };
}
