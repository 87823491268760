import mixpanel from "mixpanel-browser";
import React, { useCallback } from "react";

import { QuestionAnalyticsCode, QuestionType } from "../../../../../../../../__generated__/graphql";
import { InDetailData, InDetailDataCategory } from "../../../../../../../../shared/components/charts/types";
import Alert from "../../../../../../../../shared/components/design-system/Alert";
import { ExternalLink } from "../../../../../../../../shared/components/design-system/Button/Link";
import { RequiredNumericInput } from "../../../../../../../../shared/components/design-system/TextInput/NumericInput";
import { useOpenableWithTracking } from "../../../../../../hooks/useBoolean";
import { OrgData } from "../../../../../../hooks/useOrgData";
import { useAllowedQuestions } from "../../../../../../hooks/useReportFields";
import { SplitSettings } from "../../../../../analytics/AnalyticsInDetailPage/useUrlSplitDimension";
import tableStyles from "../../../../../analytics/tables.module.scss";
import Button from "../../../../../design-system/Button/index";
import EditLabels from "../../../EditLabels/EditLabels";
import editorStyles from "../../../styles.module.scss";

export default function InDetailDataEditor({
  value,
  onChange,
  firstSplit,
  secondSplit,
}: {
  value: InDetailData;
  onChange: React.Dispatch<React.SetStateAction<InDetailData | null>>;
  firstSplit: SplitSettings;
  secondSplit: SplitSettings;
}) {
  const questions = useAllowedQuestions();

  if (value.categories.length < 1) {
    throw new Error("Cannot edit if there is no data");
  }
  if (!firstSplit.analyticsCode && !firstSplit.questionId) {
    throw new Error("Cannot edit without at least one split");
  }

  // First split
  const grandTotalIsCalculable = totalIsCalculableFromSplit(firstSplit, questions);
  const showGrandTotalEditor = !grandTotalIsCalculable && value.grandTotal !== undefined;

  const updateDataWithCategory = useCallback(
    (categoryIndex: number, updateCategory: (category: InDetailDataCategory) => InDetailDataCategory) =>
      onChange((value) => {
        const newCategories = value!.categories.map((category, i) =>
          i === categoryIndex ? updateCategory(category) : category,
        );

        return {
          ...value,
          grandTotal: grandTotalIsCalculable ? newCategories.reduce((a, n) => a + n.count, 0) : value!.grandTotal,
          categories: newCategories,
        };
      }),
    [grandTotalIsCalculable, onChange],
  );

  const updateCategoryValue = (categoryIndex: number) => (newValue: number) =>
    updateDataWithCategory(categoryIndex, (category) => ({ ...category, count: newValue }));

  const updateGrandTotal = useCallback(
    (grandTotal: number) => onChange((value) => ({ ...value!, grandTotal })),
    [onChange],
  );

  const updateLabels = useCallback(
    (categories: InDetailData) => {
      mixpanel.track("Saved updated graph labels");
      onChange(categories);
    },
    [onChange],
  );

  const { isOpen, open, close } = useOpenableWithTracking("Opened the edit labels modal");

  if (!value.splits?.length) {
    return (
      <>
        {isOpen ? (
          <EditLabels
            isOpen={isOpen}
            closeModal={close}
            saveLabels={(newLabels) => updateLabels(newLabels)}
            graphData={value}
          />
        ) : null}
        <DocumentationLink totalText={showGrandTotalEditor} />
        <div className={editorStyles.RightAlign}>
          <Button variant="action" onClick={open} icon="edit">
            Edit Labels
          </Button>
        </div>
        <div className={tableStyles.Container}>
          <table className={tableStyles.Table}>
            <tbody>
              {value.categories.map(({ label, count }, i) => (
                <tr key={i}>
                  <th>{label}</th>
                  <td>
                    <RequiredNumericInput
                      value={count}
                      onChange={updateCategoryValue(i)}
                      label={label}
                      labelVisuallyHidden
                    />
                  </td>
                </tr>
              ))}
              {showGrandTotalEditor ? (
                <tr>
                  <th>Total</th>
                  <td>
                    <RequiredNumericInput
                      value={value.grandTotal!}
                      onChange={updateGrandTotal}
                      label="Total"
                      labelVisuallyHidden
                    />
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </>
    );
  }

  // Second split
  const categoryTotalsAreCalculable = totalIsCalculableFromSplit(secondSplit, questions);

  const updateSegmentValue = (categoryIndex: number, segmentIndex: number) => (newValue: number) =>
    updateDataWithCategory(categoryIndex, (category) => {
      const newSegments = category.segments!.map((segment, i) => (i === segmentIndex ? { count: newValue } : segment));
      return {
        ...category,
        segments: newSegments,
        count: categoryTotalsAreCalculable ? newSegments.reduce((a, n) => a + n.count, 0) : category.count,
      };
    });

  return (
    <>
      {isOpen ? (
        <EditLabels
          isOpen={isOpen}
          closeModal={close}
          saveLabels={(newLabels) => updateLabels(newLabels)}
          graphData={value}
        />
      ) : null}
      <DocumentationLink totalText={showGrandTotalEditor || !categoryTotalsAreCalculable} />
      <div className={editorStyles.RightAlign}>
        <Button variant="action" onClick={open} icon="edit">
          Edit Labels
        </Button>
      </div>
      <div className={tableStyles.Container}>
        <table className={tableStyles.Table}>
          <thead>
            <tr>
              <th />
              {value.splits.map(({ label }, i) => (
                <th key={i}>{label}</th>
              ))}
              {!categoryTotalsAreCalculable ? <th>Total</th> : null}
            </tr>
          </thead>
          <tbody>
            {value.categories.map(({ label, segments, count }, i) => (
              <tr key={i}>
                <th>{label}</th>
                {segments!.map(({ count }, j) => (
                  <td key={j}>
                    <RequiredNumericInput
                      value={count}
                      onChange={updateSegmentValue(i, j)}
                      label={`${label}, ${value.splits![j].label}`}
                      labelVisuallyHidden
                    />
                  </td>
                ))}
                {!categoryTotalsAreCalculable ? (
                  <td>
                    <RequiredNumericInput
                      value={count}
                      onChange={updateCategoryValue(i)}
                      label={`${label} total`}
                      labelVisuallyHidden
                    />
                  </td>
                ) : null}
              </tr>
            ))}
            {showGrandTotalEditor ? (
              <tr>
                <th>Total</th>
                <td colSpan={categoryTotalsAreCalculable ? value.splits.length - 1 : value.splits.length} />
                <td>
                  <RequiredNumericInput
                    value={value.grandTotal!}
                    onChange={updateGrandTotal}
                    label="Total"
                    labelVisuallyHidden
                  />
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </>
  );
}

type Question = OrgData["questions"][number];

function totalIsCalculableFromSplit(split: SplitSettings, questions: Question[]) {
  if (split.groups.basis !== "NONE") return false;

  const splitQuestions = questions.filter((q) =>
    split.analyticsCode
      ? q.analyticsCode === (split.analyticsCode as unknown as QuestionAnalyticsCode)
      : q.id === split.questionId,
  );

  if (splitQuestions.length !== 1) return false;

  const [question] = splitQuestions;

  return question.required && question.type === QuestionType.Radio;
}

function DocumentationLink({ totalText }: { totalText?: boolean }) {
  return (
    <Alert variant="info" className="ds-mb-6">
      In order to edit this data correctly, we recommend that you{" "}
      <ExternalLink to="https://docs.culture-shift.co.uk/user-guide/analytics/totals-in-detail">
        read the documentation around the Analytics system
      </ExternalLink>
      {totalText ? ", particlarly around the way 'total' values are calculated." : "."}
    </Alert>
  );
}
