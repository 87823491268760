import React, { FunctionComponent } from "react";

import { Permission } from "../../../../../__generated__/graphql";
import { OrgSwitcher } from "../../../hooks/useOrgData";
import PageHeader from "../../design-system/PageHeader";
import PageTitle from "../../design-system/PageHeader/PageTitle";

type AnalyticsHeaderProps = {
  srTitle: string;
};
const AnalyticsHeader: FunctionComponent<AnalyticsHeaderProps> = ({ srTitle }) => (
  <PageHeader>
    <div className="ds-hstack">
      <PageTitle title="Analytics" srTitle={srTitle} />
      <div className="ds-hstack ds-flex-wrap ds-ml-auto">
        <OrgSwitcher permission={Permission.Analytics} />
      </div>
    </div>
  </PageHeader>
);

export default AnalyticsHeader;
