import mixpanel from "mixpanel-browser";
import React, { useCallback } from "react";

import { FilterSettings } from "../../../hooks/filtering/useFilterFromUrlParams";
import { OrgData } from "../../../hooks/useOrgData";
import OptionCheckboxes from "../OptionChooser/OptionCheckboxes";

type Question = OrgData["questions"][number];

export default function QuestionFilter({
  question,
  optionSearch,
  filterSettings: { filter, setFilter },
}: {
  question: Question;
  optionSearch: string;
  filterSettings: FilterSettings;
}) {
  const selection = filter.questions?.find(({ questionId }) => questionId === question.id)?.value;
  const handleChange = useCallback(
    (value?: string[]) => {
      mixpanel.track("Changed the reports filter", {
        name: question.shortTitle,
        analyticsCode: question.analyticsCode,
      });
      const questionFilter = (filter.questions ?? []).filter(({ questionId }) => questionId !== question.id);
      if (value) {
        questionFilter.push({ questionId: question.id, value });
      }
      setFilter({ ...filter, questions: questionFilter });
    },
    [filter, setFilter, question],
  );

  return (
    <OptionCheckboxes
      question={question}
      selection={selection}
      optionSearch={optionSearch}
      onChange={handleChange}
      showDeleted
      showAllOption
      deletedBlurb="These options have been removed from the question during form amends, but might still appear on old reports."
    />
  );
}
