import React, { useMemo } from "react";

import OverTimeBarChart from "../../../../../shared/components/charts/OverTimeBarChart";
import "../../../../../shared/components/charts/OverTimeBarChart/styles.css";
import OverTimeTable from "../../../../../shared/components/charts/OverTimeTable";
import { OverTimeData } from "../../../../../shared/components/charts/types";
import LoadingSpinner from "../../../../../shared/components/design-system/LoadingSpinner";
import errorImage from "../../../assets/images/error.svg";
import { isViewFiltered } from "../../../hooks/filtering/helpers";
import { FilterSettings } from "../../../hooks/filtering/useFilterFromUrlParams";
import "../../../translations";
import EmptyPanel from "../../design-system/EmptyPanel";
import TitleWithActionGroup from "../../design-system/PageHeader/TitleWithActionGroup";
import { ChartTypeSettings } from "../AnalyticsInDetailPage/chart-options";
import tableStyles from "../tables.module.scss";
import { OverTimeYearSettings } from "./useUrlYearSettings";

export default function ChartPanel({
  loading,
  error,
  data,
  yearSettings,
  chartTypeSettings: { chartType },
  filterSettings: { filter, defaults },
}: {
  loading: boolean;
  error: Error | null;
  data: OverTimeData | null;
  yearSettings: OverTimeYearSettings;
  chartTypeSettings: ChartTypeSettings;
  filterSettings: FilterSettings;
}) {
  const filtered = useMemo(() => isViewFiltered(filter, defaults), [filter, defaults]);
  const title = filtered ? "Selected reports" : "All reports";

  if (loading) {
    return (
      <>
        <TitleWithActionGroup title={title} className="ds-pt-0!" headerLevel={3} />
        <LoadingSpinner />
      </>
    );
  }

  if (error || !data) {
    return (
      <>
        <TitleWithActionGroup title={title} className="ds-pt-0!" headerLevel={3} />
        <EmptyPanel image={errorImage}>
          <p>An error occurred loading this data</p>
        </EmptyPanel>
      </>
    );
  }

  if (chartType === "TABLE") {
    return (
      <>
        <TitleWithActionGroup title={title} className="ds-pt-0!" headerLevel={3} />
        <div className={tableStyles.Container}>
          <OverTimeTable
            data={data}
            year={yearSettings.year}
            comparisonYear={yearSettings.comparisonYear}
            month={yearSettings.month}
            className={tableStyles.Table}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <TitleWithActionGroup title={title} className="ds-pt-0!" headerLevel={3} />
      <OverTimeBarChart
        startMonth={yearSettings.month}
        data={data}
        year={yearSettings.year}
        comparisonYear={yearSettings.comparisonYear}
      />
    </>
  );
}
