import React, { PropsWithChildren } from "react";

import { IconSlug } from "../../../../../shared/components/design-system/Icon";
import { NavLinkContent } from "./NavigationItem";
import "./styles.scss";

export default function NavigationSubMenu({
  title,
  closeMobileNavigation,
  icon,
  isNew = false,
  isNavCollapsed,
  children,
}: PropsWithChildren<{
  title: string;
  icon: IconSlug;
  isNew?: boolean;
  closeMobileNavigation: () => void;
  isNavCollapsed: boolean;
}>) {
  return (
    <li className="c-navigation__item has-sub-menu">
      <span className="c-navigation__link" aria-hidden="true">
        <NavLinkContent isNew={isNew} icon={icon} isNavCollapsed={isNavCollapsed} title={title} />
      </span>
      <div className="c-navigation__sub-menu">
        <span className="c-navigation__sub-menu-title" aria-hidden="true">
          {title}
        </span>
        <ul onClick={closeMobileNavigation}>{children}</ul>
      </div>
    </li>
  );
}

export function NavigationSubMenuItem({ to, title }: { to: string; title: string }) {
  return (
    <li className="c-navigation__sub-menu-item">
      <a className="c-navigation__sub-menu-link is-external external-link" href={to} target="_blank" rel="noreferrer">
        {title}
      </a>
    </li>
  );
}
