import { EditorThemeClasses, NodeKey } from "lexical";
import React, { PropsWithChildren, ReactNode } from "react";

import { Caption } from "../../../../../../../shared/block-editor-data/types";
import { useSelectionProperty } from "../../hooks";
import CaptionContainer from "./Caption";
import styles from "./styles.module.scss";

export default function BlockContainer({
  children,
  actionButtons,
  captionProps,
}: PropsWithChildren<{
  actionButtons?: ReactNode;
  captionProps: {
    caption: Caption;
    /** The key of the node which the caption is attached to */
    nodeKey: NodeKey;
    theme: EditorThemeClasses;
  };
}>) {
  // Ideally this code shouldn't rely on data passed in to make the captions work, but that feels like a refactor that can be safely left until the system is more stable and/or never.
  const selected = useSelectionProperty(
    (selection) => !!selection?.getNodes().some((node) => node.__key === captionProps.nodeKey),
    [captionProps?.nodeKey],
    false,
  );

  return (
    <div className={`${styles.BlockContainer} ${selected ? styles.Selected : ""}`}>
      {actionButtons ? <div className={styles.Buttons}>{actionButtons}</div> : null}
      {children}
      {captionProps ? <CaptionContainer {...captionProps} /> : null}
    </div>
  );
}
