import React, { useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";

import { OrgData } from "../../../hooks/useOrgData";
import Badge from "../Badge";
import Checkbox from "../Checkbox";
import { filterSearchOptions } from "../FilterPanel";
import { buildMatchText } from "../FilterPanel/usePanels";
import { RadioButton } from "../RadioGroup";
import { SearchableList } from "../SearchableList";
import ToggleSwitch from "../ToggleSwitch";
import "./index.scss";
import { OptionBadge, OptionCheckboxesProps } from "./types";
import useDeletedGroups from "./useDeletedGroups";
import useExpandDeletedOnSearchMatch from "./useExpandDeletedOnSearchMatch";
import useSelection from "./useSelection";
import useSelectionFilter from "./useSelectionFilter";

type Question = OrgData["questions"][number];

export default function OptionCheckboxes<T>(props: OptionCheckboxesProps<T>) {
  // Destructure inside the function, so we can still look at 'props' as a whole to work out which of the various allowed types it is.
  // That allows us to infer the type of one prop from another, and TypeScript can be a lot smarter about what's possible.
  const {
    question,
    optionSearch,
    deletedBlurb,
    validity,
    badges,
    selection,
    showAllOption,
    showDeleted,
    alwaysExpandDeleted,
    deletedOptionsToShow,
    availableAnswers,
  } = props;

  const anyDeletedSelected = question.deletedOptions?.some((option) => selection?.includes(option.id)) ?? false;
  const [deletedExpanded, setDeletedExpanded] = useState(anyDeletedSelected);
  const optionGroups = question.optionGroups!;
  const { toggleId, selectAll } = useSelection(props);
  const containerRef = useRef<HTMLDivElement>(null);

  // Update the browser's validity field when the prop changes:
  useEffect(() => {
    if (containerRef.current) {
      const checkbox = containerRef.current.querySelector("input");
      if (checkbox) checkbox.setCustomValidity(validity ?? "");
    }
  }, [validity]);

  // Remove any options that don't exist from the selection, to prevent invalid requests
  useSelectionFilter(props);

  // Deleted options aren't stored in groups so we need to build the groups out of the data we do have.
  const deletedGroups = useDeletedGroups(question, deletedOptionsToShow, showDeleted);

  // If any deleted options match the search string, expand the 'deleted options' panel
  useExpandDeletedOnSearchMatch(showDeleted, deletedGroups, optionSearch, setDeletedExpanded);

  // Some handy flags for the render
  const hasDeleted = deletedGroups.length > 0;
  const showExpanded =
    hasDeleted && (deletedExpanded || alwaysExpandDeleted || (deletedOptionsToShow && deletedOptionsToShow.length > 0));
  const optionallyExpandable = showDeleted && !alwaysExpandDeleted;

  return (
    <div className="option-chooser" ref={containerRef}>
      <div>
        {showAllOption ? (
          <SearchableList
            items={[{ id: "all", matchText: buildMatchText(question, "", "") }]}
            fields={["matchText"]}
            searchText={optionSearch ?? ""}
            component={() => (
              <RadioButton className="ds-pt-0" checked={!selection} onClick={selectAll}>
                All
              </RadioButton>
            )}
            searchOptions={filterSearchOptions}
          />
        ) : null}
        {hasDeleted && !optionallyExpandable ? (
          <h4 className="option-chooser__category-heading">Current options</h4>
        ) : null}
        <SearchableList
          items={optionGroups.map((group) => ({
            ...group,
            id: group.id ?? uuid(),
            matchText: buildMatchText(question, group.id!),
          }))}
          fields={["matchText"]}
          searchText={optionSearch ?? ""}
          component={(group) => (
            <GroupOptionChooser
              question={question}
              groupId={group.id}
              options={
                availableAnswers
                  ? group.options
                      .filter(({ id }) => availableAnswers?.includes(id!))
                      .map(({ id, value }) => ({ id: id!, value }))
                  : group.options.map(({ id, value }) => ({ id: id!, value }))
              }
              title={optionGroups.length > 1 ? group.groupName ?? null : null}
              selection={selection ?? []}
              toggleId={toggleId}
              optionSearch={optionSearch}
              badges={badges}
            />
          )}
          searchOptions={filterSearchOptions}
        />
      </div>
      {hasDeleted ? (
        <section className="option-chooser__deleted-section">
          {optionallyExpandable ? (
            <ToggleSwitch
              label="Show deleted options"
              className="option-chooser__deleted-toggle"
              checked={deletedExpanded}
              onChange={setDeletedExpanded}
              disabled={anyDeletedSelected}
            />
          ) : (
            <h4 className="option-chooser__category-heading">Deleted options</h4>
          )}
          {showExpanded ? (
            <>
              {deletedBlurb ? <p className="option-chooser__deleted-blurb">{deletedBlurb}</p> : null}
              <SearchableList
                items={deletedGroups
                  .filter((group) =>
                    deletedOptionsToShow
                      ? group.options.some((option) => deletedOptionsToShow.includes(option.id))
                      : true,
                  )
                  .map((group) => ({
                    ...group,
                    id: group.id ?? "null",
                    matchText: buildMatchText(question, group.id!),
                  }))}
                fields={["matchText"]}
                searchText={optionSearch ?? ""}
                component={(group) => (
                  <GroupOptionChooser
                    question={question}
                    groupId={group.id!}
                    options={group.options.filter((option) =>
                      deletedOptionsToShow ? deletedOptionsToShow.includes(option.id) : true,
                    )}
                    title={
                      deletedGroups.length === 1 && optionGroups.length === 1 && group.id === optionGroups[0].id
                        ? null
                        : group.title
                    }
                    selection={selection ?? []}
                    toggleId={toggleId}
                    optionSearch={optionSearch}
                    badges={badges}
                  />
                )}
                searchOptions={filterSearchOptions}
              />
            </>
          ) : null}
        </section>
      ) : null}
    </div>
  );
}

function GroupOptionChooser({
  question,
  groupId,
  title,
  options,
  selection,
  toggleId,
  optionSearch,
  badges,
}: {
  question: Question;
  groupId: string;
  options: { id: string; value: string }[];
  title: string | null;
  selection: string[];
  toggleId: (id: string) => void;
  optionSearch?: string;
  badges?: OptionBadge[];
}) {
  return (
    <div className="option-chooser__group">
      {title ? <h4 className="option-chooser__group-heading">{title}</h4> : null}
      <SearchableList
        items={options.map((option) => ({
          ...option,
          id: option.id!,
          matchText: buildMatchText(question, groupId, option.id!),
        }))}
        fields={["matchText"]}
        searchText={optionSearch ?? ""}
        component={({ value, id }) => (
          <Checkbox onChange={() => toggleId(id!)} isSelected={selection.includes(id!)}>
            <div className="option-chooser__label-text">{value}</div>
            {badges ? (
              <div>
                {badges.map((badge, i) =>
                  badge.optionIds.includes(id) ? <Badge key={i} variant={badge.variant} label={badge.label} /> : null,
                )}
              </div>
            ) : null}
          </Checkbox>
        )}
        searchOptions={filterSearchOptions}
      />
    </div>
  );
}
