import { useMemo } from "react";

import { OrgData } from "../../../hooks/useOrgData";

type Question = OrgData["questions"][number];

export type DeletedOptionGroup = { options: Array<{ id: string; value: string }>; id?: string | null; title: string };

export default function useDeletedGroups(
  question: Question,
  deletedOptionsToShow?: string[],
  showDeleted?: boolean,
): DeletedOptionGroup[] {
  return useMemo(() => {
    if (!question.deletedOptions) return [];
    if (question.deletedOptions.length === 0) return [];
    if (!showDeleted && (!deletedOptionsToShow || deletedOptionsToShow.length === 0)) return [];

    const groups: DeletedOptionGroup[] = [];

    for (const { id, value, groupId } of question.deletedOptions) {
      if (deletedOptionsToShow && !deletedOptionsToShow.includes(id)) continue;

      const existingGroup = groups.find((group) => group.id === groupId);
      if (existingGroup) {
        existingGroup.options.push({ id: id!, value });
        continue;
      }

      const undeletedGroup = question.optionGroups!.find((group) => group.id === groupId);
      groups.push({
        id: groupId,
        options: [{ id: id!, value }],
        title: groupId
          ? (undeletedGroup
              ? undeletedGroup.groupName
              : question.deletedOptionGroups?.find((group) => group.id === groupId)?.groupName) ?? "Deleted group"
          : "Unknown group",
      });
    }

    return groups;
  }, [question, deletedOptionsToShow, showDeleted]);
}
