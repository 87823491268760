import React, { FunctionComponent, PropsWithChildren } from "react";

import "./styles.scss";

type PageHeaderProps = PropsWithChildren<{
  variant?: "small" | "regular" | "has-actions";
  className?: string;
}>;

/** @deprecated Use CombinedHeader instead of these components */
const PageHeader: FunctionComponent<PageHeaderProps> = ({ children, variant, className }) => (
  <header className={`c-page-header ${variant ? `c-page-header--${variant}` : ""} ${className ?? ""}`}>
    {children}
  </header>
);

export default PageHeader;
