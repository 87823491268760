// Usage example: "changed it from {{oldValue}} to {{newValue}}|changed it from {{oldValue}}|changed it to {{newValue}}|changed it".
import { PostProcessorModule, TOptions } from "i18next";

import { NONE_PLACEHOLDER } from "../initialisation";

// You can pass in oldValue and newValue as you have then and it'll choose the first option it can do. The last option should always be safe with neither option.
const optionalFieldProcessor: PostProcessorModule = {
  name: "optional",
  type: "postProcessor",
  process(value: string, key: string, fields: TOptions<{ [key: string]: string }>): string {
    const options = value.split("|");
    for (const option of options) {
      if (!option.includes(NONE_PLACEHOLDER)) return option;
    }
    return options[options.length - 1];
  },
};

export default optionalFieldProcessor;
