import React, { useEffect } from "react";

type PageTitleProps = {
  title: string;
  srTitle?: string;
};

/** @deprecated Use CombinedHeader instead of these components */
export default function PageTitle({ title, srTitle }: PageTitleProps) {
  useEffect(() => {
    document.querySelector("h1")?.focus();
  }, []);
  return (
    <h1 role="status" className="ds-h1 ds-m-0 c-page-header__title" tabIndex={-1}>
      {title}
      <span id="tabTitle" className="is-sr-only">
        {srTitle}
      </span>
    </h1>
  );
}
