import mixpanel from "mixpanel-browser";
import React, { useCallback } from "react";

import { filterSearchOptions } from ".";
import { ReportSearch } from "../../../../../__generated__/graphql";
import { FilterSettings } from "../../../hooks/filtering/useFilterFromUrlParams";
import Checkbox from "../Checkbox";
import { RadioButton } from "../RadioGroup";
import { SearchableList } from "../SearchableList";

type ChoiceItem = { id: string; choice?: { name: string; value: string | null }; filterText: string };

export type ChoiceSearchParamKeys = Exclude<
  keyof ReportSearch,
  "createdAt" | "updatedAt" | "questions" | "excludeSpam"
>;

export default function ChoiceFilter({
  filterKey,
  panelName,
  optionSearch,
  choices,
  filterSettings: { filter, setFilter },
}: {
  filterKey: ChoiceSearchParamKeys;
  panelName: string;
  choices: { name: string; value: string | null }[];
  optionSearch: string;
  filterSettings: FilterSettings;
}) {
  const selection = filter[filterKey] as (string | null)[];
  const toggleValue = useCallback(
    (value: string | null) => () => {
      mixpanel.track("Changed the reports filter", { name: panelName });
      if (selection?.includes(value)) {
        setFilter({ ...filter, [filterKey]: selection?.filter((selectedValue) => value !== selectedValue) });
      } else {
        setFilter({ ...filter, [filterKey]: [...(selection ?? []), value] });
      }
    },
    [panelName, selection, setFilter, filter, filterKey],
  );

  const selectAll = useCallback(() => {
    mixpanel.track("Changed the reports filter", { name: panelName });
    setFilter({ ...filter, [filterKey]: undefined });
  }, [filter, filterKey, setFilter, panelName]);

  return (
    <div className="option-chooser">
      <SearchableList<ChoiceItem>
        items={[
          { id: "all", filterText: panelName },
          ...choices.map((choice) => ({
            id: choice.value ?? "null",
            choice,
            filterText: [panelName, choice.name].join(" "),
          })),
        ]}
        fields={["filterText"]}
        searchText={optionSearch}
        component={({ id, choice }) =>
          id === "all" ? (
            <RadioButton className="ds-pt-0" checked={!selection} onClick={selectAll}>
              All
            </RadioButton>
          ) : (
            <Checkbox
              key={choice!.value}
              onChange={toggleValue(choice!.value)}
              isSelected={selection?.includes(choice!.value) ?? false}
            >
              {choice!.name}
            </Checkbox>
          )
        }
        searchOptions={filterSearchOptions}
      />
    </div>
  );
}
