import classNames from "classnames";
import React, { FunctionComponent } from "react";

import Button from "../../../../../shared/components/design-system/Button";
import {
  ExternalLinkButton,
  InternalLinkButton,
} from "../../../../../shared/components/design-system/Button/LinkButton";
import { logErrorWithId } from "../GenericError";
import styles from "./index.module.scss";

export interface EmptyStateBannerProps {
  body: string;
  callToAction?: () => void;
  title?: string;
  buttonLabel?: string;
  graphic?: string;
  direction?: "horizontal" | "vertical";
  background?: boolean;
  externalLink?: string;
  internalLink?: string;
  className?: string;
  error?: Error;
}

const EmptyStateBanner: FunctionComponent<EmptyStateBannerProps> = ({
  body,
  buttonLabel,
  callToAction,
  title,
  graphic,
  direction = "horizontal",
  background = false,
  externalLink,
  internalLink,
  className,
  error,
}) => {
  const containerClassName = classNames(styles.EmptyStateBanner, className, {
    [styles.IsVertical]: direction === "vertical",
    [styles.HasTitle]: !!title,
    [styles.HasGraphic]: !!graphic,
    [styles.HasBackground]: !!background,
  });
  return (
    <div className={containerClassName}>
      <div className={styles.Information}>
        {title ? <span className={styles.Title}>{title}</span> : null}
        <span>{body}</span>
        {error ? <span>Error ID: {logErrorWithId(error)}</span> : null}
        {externalLink && buttonLabel ? (
          <ExternalLinkButton className={styles.CtaButton} to={externalLink} size="small">
            {buttonLabel}
          </ExternalLinkButton>
        ) : internalLink && buttonLabel ? (
          <InternalLinkButton className={styles.CtaButton} to={internalLink} size="small">
            {buttonLabel}
          </InternalLinkButton>
        ) : buttonLabel ? (
          <Button onClick={callToAction} size="small" label={buttonLabel} className={styles.CtaButton}></Button>
        ) : null}
      </div>
      {graphic && <img alt="" className={styles.Illustration} src={graphic} />}
    </div>
  );
};

export default EmptyStateBanner;
